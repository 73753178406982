<template>
  <div
    class="w-min min-w-12 rounded-b p-2 pb-3 pt-8 text-center font-agrandir-wide text-xs font-thin uppercase leading-3 tracking-wide transition-all duration-300 group-hover:translate-x-4 md:text-sm1 md:leading-4 lg:min-w-16 lg:pb-4 lg:pt-10"
    :style="[color ? { background: color } : {}]"
    :class="[color ? 'text-black' : 'bg-black text-white']"
  >
    <template v-if="label">
      {{ label }}
    </template>
    <template v-else>
      {{ dayjs(date).format('DD MMM') }}
    </template>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  color?: string
  label?: string
  date?: string
}>()

const dayjs = useDayjs()
</script>
